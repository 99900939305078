import React, { useLayoutEffect, useState } from 'react';
import Storyblok, { useStoryblok } from '../utils/storyblok';

import { ResolveRelations } from '../utils/resolve-relations';
import { StoryblokComponent } from '@storyblok/react';

const PATHS_TO_EXCLUDE = ['blog-post-summaries/', 'kundenportal-dtk-club/', 'dashboard/', 'immox-team/'];

if (typeof document === 'undefined') {
  React.useLayoutEffect = React.useEffect;
}

export default function Page({ story }) {
  const [showChild, setShowChild] = useState(false);

  const enableBridge = true; // load the storyblok bridge everywhere
  // use the preview variable to enable the bridge only in preview mode
  // const enableBridge = preview;
  story = useStoryblok(story, enableBridge);

  function setupLinks() {
    const anchors = document.getElementsByTagName('a');
    for (let i = 0; i < anchors.length; i++) {
      if (/deutsche-teilkauf.de/.test(anchors[i].hostname)) {
        anchors[i].setAttribute('rel', '');
        anchors[i].setAttribute('target', '_self');
      } else {
        anchors[i].setAttribute('rel', 'noopener noreferrer nofollow');
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }

  useLayoutEffect(() => {
    setupLinks();
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  return <StoryblokComponent story={story} blok={story.content} />;
}

export async function getStaticProps({ params, preview = false }) {
  const slug = params.slug ? params.slug.join('/') : 'home';

  const sbParams = {
    version: 'published', // or "draft"
    resolve_relations: ResolveRelations,
    excluding_fields: 'content,MetaTags,headerBlocks,MetaRobots,CanonicalLink',
  };

  if (preview) {
    sbParams.version = 'draft';
  }

  let { data } = await Storyblok.get(`cdn/stories/${slug}`, sbParams);
  const { story } = data;

  if (story.name === 'Blog' && story.is_startpage) {
    data = await handleBlogMainPage(data);
  }

  if (story.full_slug.startsWith('blog/') && !story.is_startpage) {
    data = await handleBlogPostPage(data);
  }

  return {
    props: {
      story: data ? data.story : false,
      preview,
    },
    revalidate: 3600, // revalidate every hour
  };
}

export async function getStaticPaths() {
  const { data } = await Storyblok.get('cdn/links/');

  const paths = [];
  Object.keys(data.links).forEach((linkKey) => {
    if (data.links[linkKey].is_folder) {
      return;
    }

    if (PATHS_TO_EXCLUDE.some((link) => data.links[linkKey].slug.includes(link))) {
      return;
    }

    // get array for slug because of catch all
    const slug = data.links[linkKey].slug;
    let splittedSlug = slug.split('/');
    if (slug === 'home') splittedSlug = false;
    paths.push({ params: { slug: splittedSlug } });
  });

  return {
    paths: paths,
    fallback: false,
  };
}

async function getPageCount() {
  const response = await Storyblok.client.head('cdn/stories/', {
    params: { per_page: 1, page: 1, is_startpage: false, starts_with: 'blog/', token: Storyblok.accessToken },
  });
  const posts = parseInt(response.headers.total);

  return posts;
}

async function getAllBlogPosts() {
  const per_page = 100;
  const requests = [];
  const postCount = await getPageCount();
  const totalPages = Math.ceil(postCount / 100);
  for (let i = 1; i <= totalPages; i++) {
    requests.push(
      Storyblok.get('cdn/stories/', {
        per_page: per_page,
        page: i,
        starts_with: 'blog/',
        is_startpage: false,
        resolve_relations: ['blog_post.categories', 'blog_post.author'],
        excluding_fields: 'blocks,content,headerBlocks,MetaRobots,MetaTags',
      })
    );
  }
  const responses = await Promise.all(requests);
  const data = responses
    .map((response) => response.data.stories)
    .reduce((previous, current) => previous.concat(current));
  return data;
}

async function handleBlogMainPage(data) {
  const blogs = await getAllBlogPosts();
  const minimizedBlogs = blogs.map((blog) => {
    const { content } = blog;
    return {
      content: {
        title: content.title,
        author: {
          content: {
            author_image: content.author.content.author_image,
            author_name: content.author.content.author_name,
            author_position: content.author.content.author_position,
          },
        },
        excerpt: content.excerpt,
        categories: content.categories,
        featured_image: content.featured_image,
        reading_time: content.reading_time,
      },
      slug: blog.full_slug,
      full_slug: blog.full_slug,
      first_published_at: blog.first_published_at,
    };
  });

  data.story.content.body = data.story.content.body.map((element: { component: string }) => {
    if (element.component === 'blog_posts_listing') {
      const changedElement = {
        ...element,
        posts: minimizedBlogs,
      };

      return changedElement;
    }
    return element;
  });

  return data;
}

async function handleBlogPostPage(data) {
  const carouselPosts = data.rels.filter((rel) => rel.full_slug.startsWith('blog/') && !rel.is_startpage);

  const minimizedBlogs = carouselPosts.map((blog) => {
    const { content } = blog;
    return {
      content: {
        title: content.title,
        author: {
          content: {
            author_image: content.author?.content?.author_image || null,
            author_name: content.author?.content?.author_name || null,
            author_position: content.author?.content?.author_position || null,
          },
        },
        excerpt: content.excerpt,
        categories: content.categories,
        featured_image: content.featured_image,
        reading_time: content.reading_time,
      },
      full_slug: blog.full_slug,
      first_published_at: blog.first_published_at,
    };
  });

  data.story.content.blocks = data.story.content.blocks.map((block) => {
    if (block.component === 'global_reference') {
      const modifiedGlobal = block.reference.content.global.map((reference) => {
        if (reference.component === 'PostsCarousel') {
          reference.posts = minimizedBlogs;
        }
        return reference;
      });
      block.reference.content.global = modifiedGlobal;
    }
    return block;
  });

  return data;
}
